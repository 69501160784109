



import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import { Link } from "react-router-dom";
import Loginimag from "../img/handwashing.webp";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
const secretKey = "dgsrhbhsdfhdhsrje";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    checkSignInStatus();
  }, []);

  const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const checkSignInStatus = () => {
    const token = localStorage.getItem("token");
    const encryptedUserName = localStorage.getItem("userName");

    if (token && encryptedUserName) {
      const decryptedUserName = decryptData(encryptedUserName);
      setUserName(decryptedUserName);
      setIsSignedIn(true);
      console.log(decryptedUserName);
    } else {
      setIsSignedIn(false);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch(
        "https://bw-handwash.bsgindia.org/api/q1/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );
      console.log(response, "response");
      const data = await response.json();
      const token = data.token;
      const userId = data.userId;
      const userName = CryptoJS.AES.encrypt(
        data.userName,
        secretKey
      ).toString();
      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId);
      localStorage.setItem("userName", userName);

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: data.message,
        }).then(() => {
          navigate("/");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.message || "An error occurred. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Server Error",
        text: "There was a problem connecting to the server. Please try again later.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center min-h-screen">
  <div className="flex flex-col md:flex-row bg-white max-w-4xl rounded-lg shadow-lg p-8">
   
    <div className="hidden md:block md:flex-none md:w-1/2 md:mr-8">
      <img
        src={Loginimag}
        alt="Login"
        className="w-full h-auto rounded-lg shadow-md"
      />
    </div>


    <div className="flex-1">
      <div className="mb-6 text-center">
        <h2 className="text-2xl font-bold text-gray-800 mb-2">
          Welcome Back!
        </h2>
        <p className="text-gray-600 text-sm">
          Please login to your account
        </p>
      </div>
      <form onSubmit={handleLogin} id="loginForm">
        <div className="mb-6">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500 p-3"
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500 p-3"
          />
          <span className="text-sm text-red-600 hover:underline cursor-pointer mt-2 block text-right">
            <Link to="/forgot-password">Forgot Password?</Link>
          </span>
        </div>
        <button
          type="submit"
          className="w-full bg-red-500 text-white font-semibold py-3 rounded-md hover:bg-red-600 transition duration-200"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className="text-lg font-semibold animate-pulse">Please wait...</span>
          ) : (
            "Login"
          )}
        </button>
        <div className="mt-4 text-center">
          <span className="text-sm">Don't have an account? </span>
          <Link to="/register" className="text-red-500 hover:underline">
            Register
          </Link>
        </div>
      </form>
    </div>
  </div>
</div>
    </>
  );
}

export default LoginPage;