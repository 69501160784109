
import axios from 'axios';
import image from '../img/3293465.jpg';
import { toast, ToastContainer } from "react-toastify";
import Navbar from './Navbar';
import { useState } from 'react';
import Swal from 'sweetalert2';
function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleResetPassword = async () => {
    setIsLoading(true);
    if (!email) {
      Swal.fire({
        title: 'Warning!',
        text: 'Please enter your email address.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      
      return;
    }
    
    try {
      const response = await axios.post("https://bw-handwash.bsgindia.org/api/q1/forget-password", { email });
  
      if (response.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: `Reset link sent to: ${email}`,
          icon: 'success',
          confirmButtonText: 'OK',
        });      
        setEmail('')
      } else {
        Swal.fire({
          title: 'Oops!',
          text: 'Something went wrong, please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to send reset link. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      
    }finally {
      setIsLoading(false);
    }
  };

  return (
          <>
<Navbar/>
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100 p-6">

<ToastContainer/>
      <h1 className="mb-6 text-3xl font-bold text-[#bb0000]">Forgot Password</h1>
      
      <img 
        src={image}
        alt="Handwaving" 
        className="mb-8 w-56 h-56 rounded-full object-cover"
      />

      <p className="mb-4 text-center text-gray-700">
        Enter your email address below, and we’ll send you a link to reset your password.
      </p>
      
      <div className="w-full max-w-sm">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-4 w-full rounded-lg border border-gray-300 p-3 text-gray-700 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

<button
      onClick={handleResetPassword}
      className="w-full rounded-lg bg-[#bb0000] p-3 text-white font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all"
      disabled={isLoading}
    >
      {isLoading ? (
        <span className="text-lg font-semibold animate-pulse">Please wait...</span>
      ) : (
        "Send Reset Link"
      )}
    </button>
      </div>
    </div>
    </>
  );
}

export default ForgotPasswordPage;