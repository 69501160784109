import React, { useState, useEffect } from "react";
import Toastify from "toastify-js";
import Swal from "sweetalert2";
// import { ClipLoader } from "react-spinners";
import Mukul1 from "../img/Questions (1).gif";
import Navbar from "./Navbar";
import imagep from "../img/pledge.jpg";
import imageq from "../img/jjj.jpg";

const Quiz = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timer, setTimer] = useState(30);
  const [soundPlayed, setSoundPlayed] = useState(false);
  const [isQuizSubmitted, setIsQuizSubmitted] = useState(false);
  const [submissionData, setSubmissionData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [loading, setLoading] = useState(true); // Loader state

  const userId = localStorage.getItem("userId");

  const submissionsUrl = `https://bw-handwash.bsgindia.org/api/questions/submissions/${userId}`;

  useEffect(() => {
    fetch(submissionsUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response Data:", data);
        if (data.message === "Quiz already Submitted") {
          setIsQuizSubmitted(true);
          setSubmissionData(data.submissions[0]);
        } else {
          fetchQuestions();
        }
      })
      .catch((error) => {
        console.error("Error checking submission:", error);
      });
  }, []);

  const fetchQuestions = () => {
    fetch("https://bw-handwash.bsgindia.org/api/questions")
      .then((response) => response.json())
      .then((questions) => {
        setQuestions(questions);
        // setLoading(false); // Stop loader once questions are loaded
      })
      .catch((error) => {
        console.error("Error fetching quiz questions:", error);
        // setLoading(false); // Stop loader on error
      });
  };

  // Fetch submission data after the quiz is submitted
  const fetchSubmissionData = () => {
    fetch(submissionsUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.submissions.length > 0) {
          setSubmissionData(data.submissions[0]);
        }
      })
      .catch((error) =>
        console.error("Error fetching submission data:", error)
      );
  };

  useEffect(() => {
    if (isQuizSubmitted) {
      fetchSubmissionData(); // Fetch submission data when quiz is submitted
    }
  }, [isQuizSubmitted]);

  useEffect(() => {
    if (timer <= 0) return;

    const interval = setInterval(() => {
      setTimer((prevTime) => {
        if (prevTime === 1) {
          clearInterval(interval);
          handleNextClick();
        } else {
          return prevTime - 1;
        }
      });

      if (timer === 10 && !soundPlayed) {
        Toastify({
          // text: "Hurry up! 10 seconds left!",
          duration: 3000,
          gravity: "top",
          position: "right",
          // backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)",
        }).showToast();
        setSoundPlayed(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const handleOptionChange = (questionId, option) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: option,
    }));
  };

  const handlePrevClick = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setTimer(30);
    }
  };

  const handleNextClick = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setAnswers({
        ...answers,
        [questions[currentQuestionIndex]._id]:
          answers[questions[currentQuestionIndex]._id] || null,
      });
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setTimer(30);
    }
  };

  const handleSubmitQuiz = () => {
    const selectedOption = answers[questions[currentQuestionIndex]._id];
    if (selectedOption !== undefined && selectedOption !== null) {
      setAnswers({
        ...answers,
        [questions[currentQuestionIndex]._id]: selectedOption,
      });
    }

    const allAnswered = questions.every(
      (q) => answers[q._id] !== undefined && answers[q._id] !== null
    );

    if (!allAnswered) {
      Swal.fire({
        title: "Incomplete Quiz",
        text: "Please answer all questions before submitting the quiz!",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }
    setIsSubmitting(true);
    fetch(
      "https://bw-handwash.bsgindia.org/api/questions/calculate-score",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ answers, userId }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        let wrongQuestionsHtml = "";
        if (result.wrongQuestions && result.wrongQuestions.length > 0) {
          wrongQuestionsHtml = result.wrongQuestions
            .map(
              (q, index) =>
                `<p><strong>Question ${index + 1}:</strong> ${q.question}`
            )
            .join("");
        }
        Swal.fire({
          title: "Your Score",
          html: `
            <p>Your score is: <strong>${
              result.correctAnswers
            }</strong> out of <strong>${result.totalQuestions}</strong>.</p>
            ${
              wrongQuestionsHtml
                ? "<h3>Incorrect Questions:</h3>" + wrongQuestionsHtml
                : ""
            }
          `,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          setIsQuizSubmitted(true);
          setSubmissionData(result);
        });
      })
      .catch((error) => {
        console.error("Error submitting answers:", error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (
    isQuizSubmitted &&
    submissionData.correctAnswers === submissionData.totalQuestions
  ) {
    const certificateUrl = submissionData.certificatePath;
    return (
      <>

      
        <Navbar />
        {/* <div className="flex justify-center text-center">Pledge</div> */}
            
   
    
        <div
  className="tab-page p-5 rounded-lg shadow-md"
  data-tab-page="chapter-7"
>
  <h1 className="text-2xl font-bold pt-5 text-center sm:text-3xl lg:text-4xl">
   Make a Cleanliness Pledge
  </h1>
  <p className="text-center sm:text-lg lg:text-xl">Let’s make a pledge to keep up our good habits.</p>
  <div className="mx-auto max-w-full sm:max-w-2xl lg:max-w-4xl">
    <img
      src={imagep}
      alt="Physical Activity"
      className="rounded-lg my-4 h-96 w-full sm:h-72 lg:h-96"
    />
  </div>
  <p className="text-center sm:text-lg lg:text-xl">
    Congratulations on completing the journey of learning about cleanliness and hygiene. Now, go out there and use your new skills to make the world a cleaner, healthier place! 🚀
  </p>
  <h5 className="text-5xl font-semibold text-center mt-5 uppercase text-[#bb0000]">
          Congratulations!!!
        </h5>
        <div className="quiz-container max-w-3xl mx-auto p-6   mt-10 flex justify-center text-center">
          <p className="text-3xl mb-4 ">
            {" "}
            You Have Scored: {submissionData.score}/20
          </p>
        </div>
        <div className="flex justify-center text-center">
          <button
            className="btn bg-[#bb0000] text-white px-10 py-4 rounded-md hover:bg-blue-700 my-10"
            onClick={() =>
              window.open(
                `${`https://bw-handwash.bsgindia.org/${certificateUrl}`}`,
                "_blank"
              )
            }
          >
            Download Certificate
          </button>
        </div>

  
  <div className="relative my-10">
    <img
      src={imageq}
      alt="Physical Activity"
      className="rounded-lg h-auto w-full object-cover"
    />
    {/* <div className="absolute inset-0 flex items-center justify-center">
      <div className="text-4xl sm:text-5xl text-white font-bold text-center bg-opacity-70 px-4 py-2 rounded-lg">
        Time to complete the quize <br/> and get the {" "}
       
        <span
          className="text-[#FFDA00] cursor-pointer"
          // onClick={handleNavigation}
        >
          Certificate
        </span>
      </div>
    </div> */}
  </div>
</div>

      
    


        
      </>
    );
  }

  if (questions.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center space-y-4 h-screen">
      <div className="flex space-x-4">
        <div className="w-12 h-12 bg-red-500 rounded-full shadow-lg animate-bounce delay-0"></div>
        <div className="w-12 h-12 bg-orange-500 rounded-full shadow-lg animate-bounce delay-200"></div>
        <div className="w-12 h-12 bg-yellow-500 rounded-full shadow-lg animate-bounce delay-400"></div>
      </div>
      {/* <div className="text-lg font-semibold text-yellow-500 animate-pulse">Loading...</div> */}
    </div>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <>
      <Navbar />
      <div className="quiz-container max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
        <h1 className="text-4xl font-semibold text-center text-[#bb0000] mb-6">
          HandWashing Quiz
        </h1>
        <div className="flex items-center justify-between mb-6">
          <p className="text-lg">
            Question: {currentQuestionIndex + 1} of {questions.length}
          </p>
          <p className="text-lg">
            <span className="font-bold text-red-500">{timer}</span> seconds left
          </p>
        </div>

        {/* <div className="grid grid-cols-2 sm:grid-cols-1 gap-10"> */}
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10">
          <div className="mb-6">
            <img
              src={Mukul1}
              alt="Quiz Animation"
              className="w-full h-auto rounded-lg shadow-md"
            />
          </div>
          <div className="mb-6">
            <h3 className="text-xl font-semibold text-blue-700 mb-4">
              {currentQuestionIndex + 1}. {currentQuestion.question}
            </h3>

            {currentQuestion.options.map((option, index) => (
              <div className="bg-red-50 rounded-lg">
              <div
                key={index}
                className={`quiz-option p-4 mb-2 border rounded-lg cursor-pointer   ${
                  answers[currentQuestion._id] === option
                    ? "bg-blue-500 text-white"
                    : ""
                }`}
                onClick={() => {
                  handleOptionChange(currentQuestion._id, option);
                }}
              >
                <p className="">{option}</p>
              </div></div>
            ))}

            <div
              className="clear-option text-red-500 font-semibold cursor-pointer"
              onClick={() => handleOptionChange(currentQuestion._id, null)}
            >
              Clear Selection
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <button
            className="btn bg-gray-500 text-white px-6 py-2 rounded-md hover:bg-gray-600"
            onClick={handlePrevClick}
            disabled={currentQuestionIndex === 0}
          >
            Previous
          </button>
          <div>
            {currentQuestionIndex < questions.length - 1 && (
              <button
                className="btn bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 mr-4"
                onClick={handleNextClick}
              >
                Next
              </button>
            )}
            {currentQuestionIndex === questions.length - 1 && (
              <button
                className="btn bg-green-600 text-white px-6 py-2 rounded-md hover:bg-green-700"
                onClick={handleSubmitQuiz}
              >
                {isSubmitting ? (
                  <span className="text-lg font-semibold animate-pulse">Please wait...</span>
                ) : (
                  "Submit Quiz"
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Quiz;
