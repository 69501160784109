import React, { useState } from 'react';
import axios from 'axios';
import { FaInstagram, FaMapMarkerAlt, FaTwitter, FaFacebook, FaYoutube, FaWhatsapp, FaShoppingCart } from 'react-icons/fa';
import bsgLogo from '../img/small logo.png';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubscribe = async () => {
    try {
      const response = await axios.post("https://bw-handwash.bsgindia.org/api/v1/subscribe", { email });
      setMessage('Subscription successful! Thank you for subscribing.');
    } catch (error) {
      setMessage('Subscription failed. Please try again.');
    }
  };

  return (
    <footer className="bg-gradient-to-b from-red-700 to-red-600 text-white py-10">
      <div className="container mx-auto px-6 md:px-12 lg:px-20">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0">

          {/* Left Column */}
          <div className="flex flex-col items-center md:items-start text-center md:text-left md:w-1/3">
            <img src={bsgLogo} alt="BSG Logo" className="w-36 mb-4" />
            <p className="text-sm text-gray-300">
              The Bharat Scouts and Guides, National Headquarters,<br/> 16 Mahatma Gandhi Marg, I. P. Estate, New Delhi - 110002
            </p>
            <div className="flex space-x-4 mt-4">
              <a href="https://www.instagram.com/bsgindia/" target="_blank" aria-label="Instagram" className="text-white text-2xl hover:text-pink-400 transition-colors">
                <FaInstagram />
              </a>
              <a href="https://maps.app.goo.gl/wzmTgSVtqonmAnUc8" aria-label="Location" className="text-white text-2xl hover:text-red-300 transition-colors">
                <FaMapMarkerAlt />
              </a>
              <a href="https://x.com/bsgnhq" target="_blank" aria-label="Twitter" className="text-white text-2xl hover:text-blue-400 transition-colors">
                <FaTwitter />
              </a>
              <a href="https://www.facebook.com/bsgnhq" aria-label="Facebook" className="text-white text-2xl hover:text-blue-500 transition-colors">
                <FaFacebook />
              </a>
              <a href="https://www.youtube.com/@BSGIndia" target="_blank" aria-label="YouTube" className="text-white text-2xl hover:text-red-500 transition-colors">
                <FaYoutube />
              </a>
              <a href="https://www.shop.bsgindia.org/" aria-label="Cart" className="text-white text-2xl hover:text-yellow-400 transition-colors">
                <FaShoppingCart />
              </a>
            </div>
          </div>


          {/* Center Column */}
          <div className="md:w-1/3 text-center md:text-left">
            <h3 className="font-bold text-lg mb-3 text-gray-100">Contact Us</h3>
            <p className="text-gray-300">🌐 <a href="https://www.bsgindia.org" className="hover:text-white transition-colors">bsgindia.org</a></p>
            <p className="text-gray-300">📧 Email: info@bsgindia.org</p>
            <p className="text-gray-300">📞 Phone: (011) 23370724, 23378667</p>
          </div>

          {/* Right Column */}
          <div className="md:w-1/3 text-center md:text-left">
            <h3 className="font-bold text-lg mb-3 text-gray-100">Subscribe to Our Newsletter</h3>
            <input
              type="email"
              placeholder="Enter Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-2 p-2 w-full rounded-lg border-0 bg-white text-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-yellow-500"
            />
            <button
              onClick={handleSubscribe}
              className="mt-4 w-full bg-yellow-600 text-white py-2 rounded-lg hover:bg-yellow-700 transition-all"
            >
              Subscribe
            </button>
            {message && <p className="mt-2 text-sm text-gray-300">{message}</p>}
          </div>
        </div>
      </div>
      <div className="bg-red-800 text-center py-2 mt-8 -mb-12">
        <p className="text-xs text-gray-300">Copyright © 2024 All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
