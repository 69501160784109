import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './component/Home';
import Content from './component/Content';
import Footer from './component/Footer';
import Login from './component/Login';
import Register from './component/Register';
import Quiz from './component/Quize';
import ForgotPasswordPage from './component/ForgotPassword';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/content" element={<Content />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} ></Route>
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
